import {
  useAccountModal,
  useAddRecentTransaction,
} from '@rainbow-me/rainbowkit';
import {
  spaceContractAddress,
  spacePrivateKey,
} from 'config/blockchain.config';
import { ethers } from 'ethers';
import { useCallback, useState } from 'react';
import { logger } from 'utilities/logger/Logger';
import { useAccount, useProvider } from 'wagmi';
import SpaceDevContract from '../contract/abi/SpaceDev.json';

const useWallet = () => {
  const { address } = useAccount();
  const [isMinting, setIsMinting] = useState(false);
  const provider = useProvider();
  const [errorMessage, setErrorMessage] = useState('');
  const addRecentTransaction = useAddRecentTransaction();
  const { openAccountModal } = useAccountModal();

  const mintAllHandsNft = useCallback(async () => {
    try {
      setIsMinting(true);
      const wallet = new ethers.Wallet(spacePrivateKey, provider);
      const spaceDevContract = new ethers.Contract(
        spaceContractAddress,
        SpaceDevContract.abi,
        wallet,
      );

      const balance = +(await spaceDevContract.balanceOf(address, 0));
      if (balance > 0) {
        setErrorMessage('You already have this NFT');
        throw new Error('You already have this NFT');
      }

      const gasPrice = await provider.getGasPrice();
      const providerGasPrice = Math.round(+gasPrice * 1.2);

      const baseGasPrice = +ethers.utils.parseUnits('300', 'gwei');
      const tx = await spaceDevContract.mint(address, 0, 1, [], {
        gasPrice: Math.max(baseGasPrice, providerGasPrice),
      });

      if (tx?.hash) {
        addRecentTransaction({
          hash: tx?.hash,
          description: 'SpaceDev NFT Mint',
        });
        openAccountModal?.();
      }
      await tx.wait();
      logger.info('Minted NFT succesfully');
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (err: any) {
      if (err.code === 'ACTION_REJECTED') {
        setErrorMessage('User rejected transaction');
      } else {
        setErrorMessage(err.message);
      }
      throw new Error(err.message);
    } finally {
      setIsMinting(false);
    }
  }, [address, addRecentTransaction, openAccountModal, provider]);

  return {
    mintAllHandsNft,
    isMinting,
    errorMessage,
  };
};

export default useWallet;
