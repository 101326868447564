import '@rainbow-me/rainbowkit/styles.css';
import { AppBar } from 'components/AppBar/AppBar';
import { useContext } from 'react';
import { Router } from 'router/Router';
import styled from 'styled-components';
import { IntlContext } from 'utilities/i18n/intl.context';

import {
  darkTheme,
  getDefaultWallets,
  RainbowKitProvider,
} from '@rainbow-me/rainbowkit';
import { alchemyApiKey, network } from 'config/blockchain.config';
import { configureChains, createClient, WagmiConfig } from 'wagmi';
import { polygon, polygonMumbai } from 'wagmi/chains';
import { alchemyProvider } from 'wagmi/providers/alchemy';
import { publicProvider } from 'wagmi/providers/public';

const { chains, provider } = configureChains(
  network === 'polygon' ? [polygon] : [polygonMumbai],
  [alchemyProvider({ apiKey: alchemyApiKey }), publicProvider()],
);

const { connectors } = getDefaultWallets({
  appName: 'SpaceDev',
  chains,
});

const wagmiClient = createClient({
  autoConnect: true,
  connectors,
  provider,
});

const StyledDiv = styled.div`
  padding: 2rem;
  background-color: ${({ theme }) => theme.background.back};
  min-height: 100vh;

  a {
    color: ${({ theme }) => theme.palette.primary.main};
  }
`;

function App() {
  // necessary to force a render when locale is updated
  useContext(IntlContext);

  return (
    <WagmiConfig client={wagmiClient}>
      <RainbowKitProvider
        chains={chains}
        theme={darkTheme({
          overlayBlur: 'small',
          ...darkTheme.accentColors.purple,
        })}
        modalSize="compact"
        showRecentTransactions={true}
      >
        <StyledDiv>
          <AppBar />

          <main>
            <Router />
          </main>
        </StyledDiv>
      </RainbowKitProvider>
    </WagmiConfig>
  );
}

export default App;
