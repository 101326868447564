import {
  Box,
  Button,
  CircularProgress,
  Typography,
  useMediaQuery,
} from '@mui/material';
import useWallet from 'hooks/useWallet';
import { FC, useEffect } from 'react';
import { intl } from 'utilities/i18n/intl.utility';
import { useAccount } from 'wagmi';
import { useRef } from 'react';
import Parallax from 'parallax-js';
import starsVerticalImage from 'assets/images/stars-vertical.png';
import starsHorizontalImage from 'assets/images/stars-horizontal.png';
import moonImage from 'assets/images/moon.png';
import astronautImage from 'assets/images/astronaut.png';

export const Home: FC = () => {
  const { isConnected, address } = useAccount();
  const sceneRef = useRef(null);
  const { mintAllHandsNft, isMinting, errorMessage } = useWallet();
  const isMobile = useMediaQuery('(max-width:48rem)');

  useEffect(() => {
    if (sceneRef.current !== null) {
      const parallaxInstance = new Parallax(sceneRef.current);
      parallaxInstance.enable();

      return () => parallaxInstance.disable();
    }
  }, []);

  return (
    <Box sx={{ height: '100%' }}>
      <br />
      <Box ref={sceneRef} sx={{ marginLeft: '-5rem' }}>
        {isMobile ? (
          <>
            <Box data-depth="0.3" position={'absolute'} top={0} left={0}>
              <img
                src={starsVerticalImage}
                alt="stars"
                style={{ width: '100vw', height: 'auto' }}
              />
            </Box>
            <Box
              sx={{
                marginTop: '3vh',
                marginLeft: '20vw',
              }}
              data-depth="0.5"
              position={'absolute'}
              top={0}
              left={0}
            >
              <Typography color="#fff" variant="h3" fontWeight={700}>
                All hands 2023!
              </Typography>
            </Box>
            <Box
              data-depth="1.0"
              position={'absolute'}
              top={0}
              left={0}
              sx={{ marginLeft: '18vw', marginTop: '20vh' }}
            >
              <img src={moonImage} alt="stars" style={{ maxWidth: '45vw' }} />
            </Box>
            <Box
              data-depth="1.5"
              sx={{ marginLeft: '30vw', marginTop: '30vh' }}
              position={'absolute'}
              top={0}
              right={0}
            >
              <img
                src={astronautImage}
                alt="stars"
                style={{ maxWidth: '60vw' }}
              />
            </Box>
          </>
        ) : (
          <>
            <Box data-depth="0.3" position={'absolute'} top={0} left={0}>
              <img
                src={starsHorizontalImage}
                alt="stars"
                style={{ width: '100vw', height: 'auto' }}
              />
            </Box>

            <Box
              sx={{
                marginTop: '3vh',
                marginLeft: '10vw',
              }}
              data-depth="0.5"
              position={'absolute'}
              top={0}
              left={0}
            >
              <Typography color="#fff" variant="h3" fontWeight={700}>
                All hands 2023!
              </Typography>
            </Box>
            <Box
              data-depth="1.0"
              position={'absolute'}
              top={0}
              left={0}
              sx={{ marginLeft: '28vw' }}
            >
              <img src={moonImage} alt="stars" style={{ maxWidth: '35vw' }} />
            </Box>
            <Box
              data-depth="1.5"
              sx={{ marginLeft: '40vw', marginTop: '30vh' }}
              position={'absolute'}
              top={0}
              right={0}
            >
              <img
                src={astronautImage}
                alt="stars"
                style={{ maxWidth: '60vw' }}
              />
            </Box>
          </>
        )}
      </Box>
      <Box
        sx={{
          position: 'absolute',
          bottom: 5,
          left: '15%',
        }}
      >
        {isConnected && (
          <Box
            sx={{
              backgroundColor: '#ffffffbd',
              padding: '.5rem',
              borderRadius: '0.25rem',
            }}
          >
            <Typography variant="body1" mb={1} fontWeight={600}>
              {intl.translate({ id: 'Address connected' })}:{' '}
              <Typography variant="body1" fontWeight={700} component="span">
                {address?.toString().slice(0, 4) +
                  '...' +
                  address?.toString().slice(4 * -1)}
              </Typography>
            </Typography>
            <Button variant="contained" onClick={mintAllHandsNft} fullWidth>
              <Box sx={{ width: '1rem' }}></Box>
              {intl.translate({ id: 'Mint your NFT' })}
              <Box
                sx={{
                  width: '1.5rem',
                  display: 'flex',
                  alignItems: 'items-center',
                }}
              >
                {isMinting && (
                  <CircularProgress
                    size={'100%'}
                    sx={{ marginLeft: '.5rem' }}
                    color="inherit"
                  />
                )}
              </Box>
            </Button>
            <Box minHeight={16}>
              {errorMessage && (
                <Typography variant="body1" component="span">
                  {errorMessage}
                </Typography>
              )}
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  );
};
