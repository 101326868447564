import { Home } from 'components/Home/Home';
import { Text } from 'design';
import { AppRoute } from 'enums/app-route.enum';
import { FC } from 'react';
import { Route, Routes } from 'react-router-dom';
import { intl } from 'utilities/i18n/intl.utility';

export const Router: FC = () => {
  return (
    <Routes>
      <Route path={AppRoute.HOME} element={<Home />} />

      <Route
        path="*"
        element={
          <>
            <Text.h2>{intl.translate({ id: 'Error 404' })}</Text.h2>

            <Text.p>
              {intl.translate(
                {
                  id: "We couldn't find what you were looking for. If you whish you can <a>Go Home</a>",
                },
                {
                  a: (label) => <a href={AppRoute.HOME}>{label}</a>,
                },
              )}
            </Text.p>
          </>
        }
      />
    </Routes>
  );
};
