import { ConnectButton } from '@rainbow-me/rainbowkit';
import { Text } from 'design';
import { DesignContext } from 'design/DesignContext';
import { ThemeMode } from 'design/enums/theme-mode.enum';
import { FC, useContext } from 'react';
import styled from 'styled-components';
import { StyledAlign } from './appbar.styled';
import spaceLogoDarkPath from './assets/spacedev-logo-dark.svg';
import spaceLogoLightPath from './assets/spacedev-logo-light.svg';

const StyledH1 = styled(Text.h1)`
  line-height: 1.5rem;
`;

const StyledA = styled.a`
  text-decoration: none;
  line-height: 1.5rem;
`;

export const AppBar: FC = () => {
  const {
    theme: { mode },
  } = useContext(DesignContext);
  const spaceLogoPath =
    mode === ThemeMode.LIGHT ? spaceLogoLightPath : spaceLogoDarkPath;

  return (
    <>
      {/* <ConnectModal
        setBlockchainModal={setBlockchainModal}
        isOpen={blockchainModal}
      /> */}
      <StyledAlign v-center h-between>
        <StyledH1>
          <StyledA
            href="https://www.spacedev.io/"
            target="_blank"
            rel="noopener noreferrer"
            aria-label="SpaceDev CRA Template"
          >
            <img src={spaceLogoPath} alt="" height="24" />
          </StyledA>
        </StyledH1>
        <ConnectButton />
      </StyledAlign>
    </>
  );
};
